import './styles/public.scss';

/**
 * @function onload The window.onload function is called when the page is loaded
 */
window.onload = () => {
    // Write in console log the PHP value passed in enqueue_js_vars in frontend/Enqueue.php
    (() => {

    })();
    // Place your public-facing magic js 🪄 here
};
